export const GET_PRODUCTS = '/api/product/by-project-id/{projectId}';
export const ADD_PRODUCT = '/api/product';
export const GET_PRODUCT_TEMPLATE_BY_TYPE_SYSTEM =
  '/api/product-template/by-system-and-type?systemCode={systemCode}&typeCode={typeCode}';
export const GET_PRODUCT_TEMPLATE_BY_ID = '/api/product-template/{productTemplateId}';
export const UPDATE_PRODUCT = '/api/product/{productId}';
export const DELETE_PRODUCT = '/api/product/{productId}';
export const GET_PRODUCT_IMAGE_BASE64 = '/api/product/{productId}/base64-image';
export const UPDATE_TOGGLE_METAL_LINER = '/api/product/{productId}/toggle-metal-liner';
export const BULK_UPDATE_TOGGLE_METAL_LINER = '/api/product/bulk-update-metal-liners';
export const UPDATE_TIMBER_LINER = '/api/product/{productId}/update-timber-liner';
export const GET_PREFAB = '/api/prefab';
export const GENERATE_PRODUCT_NAME = '/api/product/generate-new-name';
export const DUPLICATE_PRODUCT = '/api/product/{productId}/duplicate?moveToLast=true';
export const INSERT_PRODUCT = '/api/product/{selectedProductId}/insert-new-product';
export const UPDATE_DEPLOYMENT_CONFIG = '/api/product/update-deployment-config';
export const GENERATE_ANGLE_LIST = '/api/polygon-support/generate-angle-list';
export const BULK_DELETE_PRODUCT = '/api/product/bulk';
